.container {
    position: relative;
    width: 5.9in !important; 
    height: 9.3in; 
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #CAE9F7; /* Example color, replace with actual value from your theme */
    padding: 20px;
  }
  
  .box {
    position: relative;
    width: 3.6in; /* 10.5cm in inches */
    height: 2.3in; /* 6.5cm in inches */
    border: 1px solid #c62828; /* Example color, replace with actual value */
    border-radius: 5px;
    background-color: white;
    margin-bottom: 16px;
  }

  .gridBox {
    width: 5.5in; /* Set the desired width */
    height:1.5in; /* Set the desired height */
    border: 1px solid #c62828; /* Border for the box */
    position: relative;
    display: flex;
    flex-direction: column; /* Align rows vertically */
    justify-content: space-between; /* Space rows evenly */
    background-color: white;
  }
  
  .gridRow {
    flex: 1; /* Make each row take equal height */
    border-bottom: 1px solid #c62828; /* Line between rows */
  }

  .boxContainer1 {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .diagnosisInput {
    width: 100px; /* Adjust width as needed */
    border: none;
    font-size: 20px;
    padding: 5px;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    letter-spacing: 10px; /* Space out the characters */
    background: linear-gradient(to right, #fff 33%, #ccc 33%, #ccc 66%, #fff 66%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
.boxContainer {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.compartment {
  border: 1px solid #c62828;
  width: 23px;
  height: 30px;
  text-align: center;
  font-size: 14px;
  margin-right: 1px;
  border-radius: 1px;
  outline: none;
}

  
  .diagnosisInput::placeholder {
    color: #bbb;
  }
  
  
  /* Remove the bottom border from the last row */
  .gridRow:last-child {
    border-bottom: none;
  }
  
  
  .subbox {
    position: relative;
    width: 3.6in; /* 10.5cm in inches */
    height: 1in; /* 6.5cm in inches */
    border-radius: 2px;
    background-color: white;
  }
  
  .textField {
    width: 100%;
    font-size: 12px  !important;
  }
  
  .form {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .typography {
    font-size: 12px !important;
    margin-bottom: 0px !important;
    color: #c62828 !important; /* Example color, replace with actual value */
    text-align: left !important;
    padding-left: 1px !important;
  }
  