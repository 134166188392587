.container {
  position: relative;
  width: 7in !important; /* A4 width */
  height: 16in; /* A4 height */
  border: 2px solid #939fed !important;
  border-radius: 1px;
  background-color: #cdd1eb; /* Example color, replace with actual value from your theme */
  padding: 10px; /* Reduced padding to maximize space */
  box-sizing: border-box; /* Ensures padding is included in width/height */
}

.typography {
  font-size: 12px !important; /* Slightly smaller font for compactness */
  font-weight: bold !important;
  white-space: normal !important;
  text-align: left !important; /* Ensure text is left-aligned */
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid red;
  background: white;
}

.tableCell {
  padding: 1px; /* Reduce padding to save space */
  border-left: 1px solid red;
  font-size: 10px; /* Adjust font size for compactness */
}



/* Additional Styles */
.textArea {
  width: 100%;
  background: white;
  font-size: 12px;
}

.input {
  width: 100%;
  background: white;
  font-size: 12px;
  padding: 2px; /* Reduced padding */
}
