.cardSubtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  /* background-color: grey;  */
  padding: 8px; /* Optional: Add padding if needed */
  border-radius: 4px; /* Optional: Add border radius if needed */
  padding: 16px;
  /* color: black;   */
  text-transform: none;
  font-size: 16px;
}
