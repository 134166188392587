.container {
  position: relative;
  width: 5.9in !important; /* A5 width */
  height: 9.27in; /* A5 height */
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #CAE9F7; /* Example color, replace with actual value from your theme */
  padding: 20px;
}

.box {
  position: absolute;
  width: 3.6in; /* 10.5cm in inches */
  height: 2.3in; /* 6.5cm in inches */
  border: 1px solid #c62828; /* Example color, replace with actual value */
  border-radius: 5px;
  background-color: white;
}

.subbox {
  position: absolute;
  width: 1.2in; /* 10.5cm in inches */
  height: 0.65in; /* 6.5cm in inches */
  border-radius: 5px;
  background-color: white;
}

.textField {
  width: 100%;
  font-size: 12px  !important;
}

.form {
  position: relative;
  width: 100%;
  height: 100%;
}

.typography {
  font-size: 12px !important;
  margin-bottom: 0px !important;
  color: #c62828 !important; /* Example color, replace with actual value */
  text-align: left !important;
  padding-left: 1px !important;
}

.diagonalBackground {

  overflow: hidden;
}

.diagonalBackground::before {
  content: 'Entlassmanagement';
  position: absolute;
  top: 50%;
  left: -50%;
  width: 200%;
  height: 50px; /* Adjust the thickness of the line */
  background-color: rgba(171, 168, 168, 0.2); /* Semi-transparent grey background */
  color: rgb(186, 178, 178); /* Color of the text */
  font-size: 25px;
  font-weight: bold; /* Make the text bold */
  text-align: center;
  line-height: 50px; /* Vertically center the text */
  transform: rotate(-30deg);
  z-index: 0; /* Ensure the line is behind the content */
  pointer-events: none; /* Ensure it doesn't interfere with interactions */
}





