.rubber-stamp {
  display: inline-block;
  text-align: center;
  border: 1px solid currentColor;
  border-radius: 50%;
  transform: rotate(-5deg);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 100%);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  text-transform: capitalize;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  user-select: none;
  overflow: hidden;
}

/* Diagonal lines overlay */
.rubber-stamp::before {
  content: "";
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: repeating-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 5px,
    rgba(255, 255, 255, 0.1) 5px,
    rgba(255, 255, 255, 0.1) 10px
  );
  pointer-events: none;
  opacity: 0.5;
}

.rubber-stamp:not(:disabled):hover {
  transform: rotate(0deg) scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
}

.rubber-stamp:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* The CSS is generated with the combination of myself and ChatGPT. */