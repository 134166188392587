/* General styles for scrollbars */
.scrollable {
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  width: 3px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #E7E8D8;
  border-radius: 3px;
  margin: 20px 0;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Styles for containers with rounded corners */
/* .rounded-container {
  border-radius: 22px;
  background: #E7E8D8 !important;
  width: 100%;
  flex-grow: 1;
} */