.container {
    position: relative;
    width: 7in !important; 
    height: 13in;
    border-radius: 5px;
    background-color: white; /* Example color, replace with actual value from your theme */
    padding: 10px;
  }
  
  .box {
    width: 100%;
    border: 2px solid #069191; /* Example color, replace with actual value */
    margin:2px !important;
    background-color: white;
    margin-top:2px;
  }
  
  .subbox {
    position: absolute;
    width: 1.2in; /* 10.5cm in inches */
    height: 0.65in; /* 6.5cm in inches */
    border-radius: 5px;
    background-color: white;
  }
  
  .textField {
    width: 100%;
    font-size: 12px  !important;
  }
  
  .form {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .typography {
    word-break: break-word;
    font-size: 13px !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
    color: #28ccccf3 !important; /* Example color, replace with actual value */
    text-align: left !important;
    padding-left: 1px !important;
  }
  