.DateRangePicker_picker {
  z-index: 1300 !important;
}

.DateRangePickerInput_arrow {
  position: relative;
}

.CalendarDay__blocked_calendar{
  background: #367b9a !important;
  color: #fff !important;
}

.CalendarDay__highlighted_calendar {
  background: #b2dbef !important;
  color: #fff !important;
}
