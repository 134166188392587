.fc-event-main {
  font-size: 1.2em;
}

.fc-event.selected {
  box-shadow: 0 0 0 2px var(--fc-neutral-text-color);
}

:root {
  --fc-today-bg-color: #inherit; /* Replace #yourColorHere with whatever color you want */
}

.fc .fc-non-business {
  background-color: rgb(0 48 89 / 50%);
}

.fc table {
  font-size: 0.9em; /* Size affects the times on the left of the calendar as well as in the events */
}

#external-events {
  /* flex-direction: column; */
  /* align-items:first baseline; */
  /* position: relative; */
  z-index: 3;
}

#external-events .fc-event {
  cursor: move;
  margin: 10px 5px;
  background-color: transparent;
  border: none;
}

#external-events p {
  display: flex;
  align-items: center;
}


#external-cancelled-events {
  /* flex-direction: column; */
  /* align-items:first baseline; */
  /* position: relative; */
  z-index: 3;
}

#external-cancelled-events .fc-event {
  cursor: move;
  margin: 10px 5px;
  background-color: transparent;
  border: none;
}

#external-cancelled-events p {
  display: flex;
  align-items: center;
}


.unscheduled-events {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 0.5rem;
  color: black;
  background-color: #fff;
  border-radius: 0.5rem;
}

.draggable-div {
  /* position: fixed; */
  cursor: grab;
}

.draggable-div:active {
  cursor: grabbing;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.striped-event {
  background: 
    linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.5) 100%),
    repeating-linear-gradient(
      -45deg,
      transparent 0 5px,
      rgb(85, 85, 85) 5px 10px
    );
  background-blend-mode: normal;
  /*  background: repeating-linear-gradient(
    -45deg,
    transparent 0 5px,
    rgb(85, 85, 85) 5px 10px
  );
  .fc-event-main b {
    display: none;
  } */
}

.striped-cancelled-event {
  background-color: #fffefe !important; /* Use !important to ensure it overrides */
  text-decoration: line-through !important;
}

.attended-event {
  background: repeating-linear-gradient(-45deg, transparent 0 5px, rgb(85, 85, 85) 5px 10px);
}

.fc-bg-event {
  color: white;
  font-size: 9px;
  background-color: #9c1a35 !important;
  opacity: 0.5 !important;
  margin: 0 -2px 0 -3px;
  padding: 3px 0 0 7px;
  border-radius: 4px;
}

.fc .fc-timegrid-slot-label .pa-unavailable {
  background-color: #9c1a35;
}

.fc-timegrid-slot:focus{
  outline: 2px solid #007bff;
  outline-offset: -2px;
}

.fc-timegrid-event-harness.focused a {
  outline: 2px solid #007BFF !important;
}

.fc-event.selected {
  border: 2px solid #007BFF !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .fc-timegrid-event-harness.focused::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.412);
  z-index: 1;
  pointer-events: none;
}

.fc-event.selected::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.412);
  z-index: 1;
  pointer-events: none;
} */

.fc-timegrid-slots tr {
  line-height: 10px !important;
}
.fc-timegrid-slots td {
  height: unset !important;
}

.fc .fc-timegrid-slot {
  font-size: 1em;
}
.fc .fc-timegrid-slot-label {
  vertical-align: top;
}