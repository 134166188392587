/* FloatingButton.module.css */
.fab {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.fab:hover {
  transform: scale(1.1);
}

.camera {
  background-color: #007bff; /* Blue for Camera */
}

.file {
  background-color: #28a745; /* Green for File */
}
